<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="admin.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="admin.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-9" v-if="isEditing">
                <h4
                  class="
                    flex
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.avatar') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div
                    class="
                      w-20
                      h-20
                      sm:w-24 sm:h-24
                      flex-none
                      lg:w-32 lg:h-32
                      image-fit
                      relative
                      m-auto
                      cursor-pointer
                    "
                  >
                    <div>
                      <img
                        alt="Icewall Tailwind HTML Admin Template"
                        class="rounded-full"
                        :src="
                          model.avatar
                            ? model.avatar
                            : '/images/default-avatar.svg'
                        "
                      />
                      <div
                        class="
                          absolute
                          mb-1
                          mr-1
                          flex
                          items-center
                          justify-center
                          bottom-0
                          right-0
                          bg-theme-17
                          rounded-full
                          p-2
                        "
                      >
                        <CameraIcon class="w-4 h-4 text-white" />
                      </div>
                    </div>
                    <input
                      type="file"
                      id="profileImage"
                      name="upload"
                      accept="image/png, image/jpeg, image/jpg"
                      style="position: absolute; top: 0%; opacity: 0 !important"
                      class="
                        cursor-pointer
                        w-20
                        h-20
                        sm:w-24 sm:h-24
                        flex-none
                        lg:w-32 lg:h-32
                        image-fit
                        relative
                        m-auto
                      "
                      @input="emitEvent($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-9" v-if="isEditing">
                <h4
                  class="
                    text-lg
                    flex
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.userName') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.userName"
                    :placeholder="i18n('iam.placeholder.userName')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-9">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    flex
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.email') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="email"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    :disabled="isEditing"
                    v-model="model.email"
                    :placeholder="i18n('iam.placeholder.email')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-9" v-if="isEditing">
                <h4
                  class="
                    flex
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.phoneNumber"
                    mode="international"
                    :inputOptions="isRTL ? optionsAr : options"
                    :dropdownOptions="telDropOptions"
                  ></vue-tel-input>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-9" v-if="!isEditing">
                <h4
                  class="
                    flex
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.password') }}
                </h4>
                <div
                  class="
                    sm:col-span-6
                    lg:col-span-4
                    col-span-12
                    lg:mt-0
                    mt-2
                    required
                    relative
                  "
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    disabled
                    class="form-control w-full dark:placeholder-white"
                    v-model="model.password"
                  />
                </div>
                <div
                  class="
                    items-center
                    flex
                    lg:col-span-2
                    col-span-6
                    mt-3
                    md:mt-0
                  "
                >
                  <ShuffleIcon
                    class="cursor-pointer mx-3"
                    @click="generatePassword"
                  />
                  <CopyIcon class="cursor-pointer" @click="copyPassword" />
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-32
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'
import { FileUploader } from '@/shared/uploader/file-uploader'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.id,
  fields.userName,
  fields.email,
  fields.phoneNumber,
  fields.pagesAccess,
  fields.avatar,
  fields.lang
])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup() {
    const errorMessage = ref('')

    return {
      errorMessage
    }
  },
  data() {
    return {
      image: null,
      imageUrl: '',
      uploadLoading: false,
      model: null,
      rules: formSchema.rules(),
      options: { placeholder: 'Enter Your Phone Number', showDialCode: true },
      optionsAr: { placeholder: 'ادخل رقم تليفونك', showDialCode: true },
      telDropOptions: {
        showFlags: true
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'admin/form/record',
      findLoading: 'admin/form/findLoading',
      saveLoading: 'admin/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(this.record || {})
    if (!this.isEditing) this.generatePassword()
  },
  methods: {
    ...mapActions({
      doNew: 'admin/form/doNew',
      doFind: 'admin/form/doFind',
      doCreate: 'admin/form/doAdd',
      doUpdate: 'admin/form/doUpdate'
    }),
    emitEvent(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.avatar = this.imageUrl
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    generatePassword() {
      var generator = require('generate-password')
      this.model.password = generator.generate({
        length: 10,
        numbers: true
      })
    },
    copyPassword() {
      navigator.clipboard.writeText(this.model.password)
      Message.success(this.i18n('common.clipboard'))
    },
    isFormValid() {
      for (var key in this.model) {
        var value = this.model[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notifications.create.popup.emptyFields'
            return false
          }
        }
      }
      if (!this.validEmail(this.model.email)) {
        this.errorMessage = 'Please enter a valid e-mail'
        return false
      }
      return true
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    doCancel() {
      this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      if (this.image) {
        this.uploadLoading = true
        const profileImage = document.getElementById('profileImage').files[0]
        const path = `admin/avatars/profile/${this.record.id}`
        const { publicUrl } = await FileUploader.upload(
          path,
          profileImage,
          'avatar'
        )
        this.model.avatar = publicUrl
      }

      this.model.lang = this.language
      const { id, email, ...values } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values
        })
      } else {
        await this.doCreate({ email, ...values })
      }
    }
  }
})
</script>
